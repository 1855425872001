<template>
  <footer class="px-8 overflow-hidden">
    <div
      class="-mt-5 h-5 self-center border-b border-b-gray-300 shadow-lg"
    ></div>
    <section
      class="mt-4 py-2 px-2 flex flex-col sm:flex-row justify-around text-sm"
    >
      <p class="mt-2 self-center text-blue-gray-600">
        &copy; {{ new Date().getFullYear() }} Kongsvinger Misjonsforsamling
      </p>
      <BedeLink
        :to="{ path: '/juridisk/informasjonskapsler' }"
        class="mt-2 self-center text-green-600 hover:text-green-500 focus:text-green-500"
      >
        Informasjonskapsler
      </BedeLink>
      <BedeLink
        :to="{ path: '/juridisk/personvern' }"
        class="mt-2 self-center text-green-600 hover:text-green-500 focus:text-green-500"
      >
        Personvern
      </BedeLink>
      <!-- <BedeLink
        :to="{ path: '/juridisk/bruksvilkar' }"
        class="mt-2 self-center text-green-600 hover:text-green-500 focus:text-green-500"
      >
        Bruksvilkår
      </BedeLink> -->
    </section>
  </footer>
</template>
