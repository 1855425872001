<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1900 1400"
    width="100%"
    height="100%"
  >
    <title>Bedehuset</title>
    <g>
      <g>
        <g>
          <path
            d="M1637.85,916.605L1393.64,916.605L1393.64,1206.98L1637.85,1206.98L1637.85,916.605ZM1865.39,415.221L1865.39,1371.06L1234.02,1371.06L1234.02,868.4L954.706,689.698L954.706,667.058L1067.02,667.058L1067.02,415.221L1404.32,415.221L1404.32,667.058L1628.94,667.058L1628.94,415.221L1865.39,415.221Z"
          />
          <path
            d="M1627.92,354.297L1627.92,373.375L1403.18,373.375L1403.18,354.297L1516.63,287.522L1627.92,354.297Z"
            style="fill-rule: nonzero"
          />
          <path
            d="M1413.35,1180.53L1619.4,1180.53L1619.4,1071.9L1413.35,1071.9L1413.35,1180.53ZM1616.86,942.043L1413.35,942.043L1413.35,1043.29L1616.86,1043.29L1616.86,942.043ZM1393.64,916.605L1637.85,916.605L1637.85,1206.98L1393.64,1206.98L1393.64,916.605Z"
            style="fill-rule: nonzero"
          />
          <path
            d="M1595.11,419.545L1444.01,419.545L1444.01,630.554L1595.11,630.554L1595.11,419.545ZM1403.18,373.375L1627.92,373.375L1627.92,392.454L1628.94,392.454L1628.94,667.058L1404.32,667.058L1404.32,392.454L1403.18,392.454L1403.18,373.375Z"
            style="fill-rule: nonzero"
          />
          <path
            d="M1209.6,887.733L1209.6,1371.06L1180.6,1371.06L1180.6,887.733L1209.6,887.733Z"
            style="fill-rule: nonzero"
          />
          <path
            d="M1195.86,857.462L713.426,857.462L954.579,703.18L1195.86,857.462Z"
            style="fill-rule: nonzero"
          />
          <path
            d="M1153.76,887.733L1153.76,1371.06L834.384,1371.06L834.384,1346.38L1067.52,1346.38L1067.52,887.733L1153.76,887.733Z"
            style="fill-rule: nonzero"
          />
          <rect x="834.384" y="896.509" width="233.14" height="449.872" />
          <path
            d="M1066,373.375L841.252,373.375L841.252,354.297L954.706,287.522L1066,354.297L1066,373.375Z"
            style="fill-rule: nonzero"
          />
          <path
            d="M841.252,373.375L1066,373.375L1066,392.454L1067.02,392.454L1067.02,667.058L842.27,667.058L842.27,392.454L841.252,392.454L841.252,373.375ZM1033.06,630.554L1033.06,419.545L882.08,419.545L882.08,630.554L1033.06,630.554Z"
            style="fill-rule: nonzero"
          />
          <path
            d="M834.384,896.509L834.384,1371.06L764.811,1371.06L764.811,887.733L1067.52,887.733L1067.52,896.509L834.384,896.509Z"
            style="fill-rule: nonzero"
          />
          <rect x="706.049" y="887.733" width="31.798" height="483.323" />
          <path
            d="M713.426,857.462L1195.86,857.462L954.579,703.18L713.426,857.462ZM1234.02,868.4L675.396,868.4L954.706,689.698L1234.02,868.4Z"
            style="fill-rule: nonzero"
          />
          <path
            d="M496.821,354.297L496.821,373.375L272.076,373.375L272.076,354.297L385.53,287.522L496.821,354.297Z"
            style="fill-rule: nonzero"
          />
          <path
            d="M462.861,419.545L311.759,419.545L311.759,630.554L462.861,630.554L462.861,419.545ZM272.076,373.375L496.821,373.375L496.821,667.058L272.076,667.058L272.076,373.375Z"
            style="fill-rule: nonzero"
          />
          <path
            d="M272.076,916.605L520.097,916.605L520.097,1206.98L272.076,1206.98L272.076,916.605ZM294.334,1043.29L497.839,1043.29L497.839,942.043L294.334,942.043L294.334,1043.29ZM294.334,1180.53L497.839,1180.53L497.839,1071.9L294.334,1071.9L294.334,1180.53Z"
            style="fill-rule: nonzero"
          />
          <path
            d="M272.076,392.454L34.612,392.454L106.728,28.944L1760.2,28.944L1865.39,392.454L1627.92,392.454L1627.92,354.297L1516.63,287.522L1403.18,354.297L1403.18,392.454L1066,392.454L1066,354.297L954.706,287.522L841.252,354.297L841.252,392.454L496.821,392.454L496.821,354.297L385.53,287.522L272.076,354.297L272.076,392.454Z"
            style="fill-rule: nonzero"
          />
          <path
            d="M272.076,916.605L272.076,1206.98L520.097,1206.98L520.097,916.605L272.076,916.605ZM679.848,1371.06L48.475,1371.06L48.475,415.221L272.076,415.221L272.076,667.058L496.821,667.058L496.821,415.221L842.27,415.221L842.27,667.058L954.706,667.058L954.706,689.698L675.396,868.4L679.848,868.4L679.848,1371.06Z"
            style="fill-rule: nonzero"
          />
        </g>
        <g>
          <path
            d="M106.728,28.944L1760.2,28.944"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M106.728,28.944L34.612,392.454"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M34.612,392.454L272.076,392.454"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1627.92,392.454L1865.39,392.454"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M272.076,392.454L272.076,354.297"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M496.821,392.454L496.821,354.297"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M272.076,354.297L385.53,287.522"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M496.821,392.454L841.252,392.454"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M496.821,354.297L385.53,287.522"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M841.252,392.454L841.252,354.297"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1066,392.454L1066,354.297"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M841.252,354.297L954.706,287.522"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1066,354.297L954.706,287.522"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1403.18,392.454L1403.18,354.297"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1627.92,392.454L1627.92,354.297"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1403.18,354.297L1516.63,287.522"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1627.92,354.297L1516.63,287.522"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1066,392.454L1403.18,392.454"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1760.2,28.944L1865.39,392.454"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M272.076,392.454L272.076,667.058"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M272.076,667.058L496.821,667.058"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M496.821,667.058L496.821,392.454"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M311.759,630.554L311.759,419.545"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M311.759,419.545L462.861,419.545"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M462.861,419.545L462.861,630.554"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M462.861,630.554L311.759,630.554"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M272.076,373.375L496.821,373.375"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M842.27,392.454L842.27,667.058"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M842.27,667.058L1067.02,667.058"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1067.02,667.058L1067.02,392.454"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M882.08,630.554L882.08,419.545"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M882.08,419.545L1033.06,419.545"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1033.06,419.545L1033.06,630.554"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1033.06,630.554L882.08,630.554"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M842.27,373.375L1067.02,373.375"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1404.32,392.454L1404.32,667.058"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1404.32,667.058L1628.94,667.058"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1628.94,667.058L1628.94,392.454"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1444.01,630.554L1444.01,419.545"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1444.01,419.545L1595.11,419.545"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1595.11,419.545L1595.11,630.554"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1595.11,630.554L1444.01,630.554"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1404.32,373.375L1628.94,373.375"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1234.02,868.4L675.396,868.4L954.706,689.698L1234.02,868.4Z"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1195.86,857.462L713.426,857.462L954.579,703.18L1195.86,857.462Z"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M679.848,868.4L679.848,1360.75"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1234.02,868.4L1234.02,1371.06"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M679.848,1360.75L679.848,1371.06"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M706.049,887.733L706.049,1371.06"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M706.049,887.733L1209.6,887.733"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M679.848,1371.06L1234.02,1371.06"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1209.6,887.733L1209.6,1365.84"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M737.847,887.733L737.847,1371.06"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M764.811,1371.06L764.811,887.733"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1153.76,887.733L1153.76,1371.06"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1180.6,1371.06L1180.6,887.733"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M834.384,896.509L1067.52,896.509"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M834.384,896.509L834.384,1346.38"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1067.52,896.509L1067.52,1346.38"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M834.384,1346.38L1067.52,1346.38"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M34.612,415.221L272.076,415.221"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M34.612,415.221L34.612,392.454"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M496.821,415.221L688.624,415.221"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M688.624,415.221L842.27,415.221"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1067.02,415.221L1403.18,415.221"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1628.94,415.221L1865.39,415.221"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1865.39,392.454L1865.39,415.221"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1234.02,1371.06L1849.24,1371.06"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1865.39,415.221L1865.39,1371.06"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1849.24,1371.06L1865.39,1371.06"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M679.848,1371.06L48.475,1371.06"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M48.475,415.221L48.475,1371.06"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M272.076,1206.98L272.076,916.605"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M272.076,916.605L520.097,916.605"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M520.097,916.605L520.097,1206.98"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M520.097,1206.98L272.076,1206.98"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M294.334,1043.29L294.334,942.043"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M497.839,1043.29L497.839,942.043"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M294.334,942.043L497.839,942.043"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M294.334,1043.29L497.839,1043.29"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M294.334,1071.9L497.839,1071.9"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M497.839,1071.9L497.839,1180.53"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M497.839,1180.53L294.334,1180.53"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M294.334,1180.53L294.334,1071.9"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1393.64,1206.98L1393.64,916.605"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1392.37,916.605L1640.39,916.605"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1637.85,916.605L1637.85,1206.98"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1640.39,1206.98L1392.37,1206.98"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1413.35,1043.29L1413.35,942.043"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1616.86,1043.29L1616.86,942.043"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1414.63,942.043L1618.13,942.043"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1414.63,1043.29L1618.13,1043.29"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1414.63,1071.9L1618.13,1071.9"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1619.4,1071.9L1619.4,1180.53"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1618.13,1180.53L1414.63,1180.53"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
          <path
            d="M1413.35,1180.53L1413.35,1071.9"
            style="fill: none; fill-rule: nonzero; stroke-width: 20px"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
