<template>
  <div class="flex flex-col flex-grow print:bg-white">
    <BedeHeader class="sticky z-50 top-0 left-0 right-0" />
    <div
      class="flex flex-col flex-grow w-full max-w-4xl mx-auto print:max-w-none"
    >
      <slot />
    </div>
    <BedeFooter class="w-full max-w-4xl mx-auto print:hidden" />
    <CookieBanner />
  </div>
</template>
