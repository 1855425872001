<template>
  <section
    class="sticky z-20 bottom-0 left-0 right-0 bg-blue-gray-700 dark:bg-gray-300 print:hidden"
  >
    <div
      v-if="showCookieBanner"
      class="w-full max-w-4xl mx-auto px-2 py-4 flex flex-row justify-center items-center text-blue-gray-100 dark:text-blue-gray-900"
    >
      <div class="flex flex-col mr-19 sm:flex-row sm:mr-0 items-center">
        <p class="text-center">
          Vi bruker bruker kun nødvendige funksjonelle informasjonskapsler.
        </p>
        <div class="whitespace-nowrap">
          <button
            class="w-20 px-2 py-1 m-1 ml-3 bg-green-600 hover:bg-green-500 focus:bg-green-500 active:bg-blue-gray-800 rounded-md shadow-lg"
            @click="accept()"
          >
            Ok
          </button>
          <NuxtLink to="/juridisk/informasjonskapsler">
            <button
              class="w-20 px-2 py-1 m-1 bg-green-600 hover:bg-green-500 focus:bg-green-500 active:bg-blue-gray-800 rounded-md shadow-lg"
            >
              Les mer
            </button>
          </NuxtLink>
          <button
            class="px-2 py-1 m-1 self-center text-white border-1 border-white hover:bg-green-500 hover:border-green-500 focus:bg-green-500 focus:border-green-500 active:bg-blue-gray-800 rounded-md"
            @click="cancel()"
          >
            X
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const cookieConsentState = useCookieConsent();
const cookieConsentBannerDismissed = useCookieConsentBannerDismissed();
const showCookieBanner = computed(
  () =>
    cookieConsentState.value !== "Accepted" &&
    !cookieConsentBannerDismissed.value
);
const accept = () => {
  cookieConsentState.value = "Accepted";
};
const cancel = () => {
  cookieConsentBannerDismissed.value = true;
};
</script>
