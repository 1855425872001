<template>
  <div class="w-full h-12 bg-white shadow-lg print:shadow-none">
    <header
      class="relative w-full h-full max-w-4xl mx-auto flex justify-center sm:justify-between items-center print:max-w-none"
    >
      <NuxtLink to="/" class="flex items-center rounded-lg">
        <div
          class="w-8 h-8 p-1.0 mx-2 bg-white border border-green-600 fill-green-600 stroke-white hover:border-green-500 hover:fill-green-500 focus:border-green-500 focus:fill-green-500 active:border-blue-gray-700 active:fill-blue-gray-700 rounded-full print:hidden"
        >
          <SvgHouse class="stroke-white" />
        </div>
        <div
          class="pr-2 text-xl text-green-600 hover:text-green-500 focus:text-green-500 active:text-blue-gray-700 print:pl-2"
        >
          {{ Location.locationName }}
        </div>
      </NuxtLink>
      <div
        id="menu"
        class="fixed w-15 h-15 right-4 bottom-4 p-1 bg-green-600 text-white text-xs rounded-full shadow-lg hover:bg-green-500 focus:bg-green-500 active:bg-blue-gray-700 sm:absolute sm:h-10 sm:w-10 sm:right-3 sm:bottom-1 sm:p-0 sm:rounded-lg sm:bg-white sm:text-blue-gray-800 sm:hover:bg-white sm:focus:bg-white sm:active:bg-white print:hidden"
      >
        <button
          id="menuButton"
          class="h-full w-full flex justify-center items-center border-1 rounded-full px-3 sm:(border-1 rounded-lg hover:text-green-600 focus:text-green-600 active:text-blue-gray-700 active:transform active:scale-80 shadow-none)"
          type="button"
          @click="menuVisible = !menuVisible"
          aria-controls="navbarSupportedContentX"
          aria-expanded="false"
          :aria-label="I18nNO.menuAria"
        >
          <span class="transform rotate-270 -ml-5.8 -mr-4">{{
            I18nNO.menu
          }}</span>
          <span v-if="menuVisible" class="ml-2.4 -mr-2.8 text-3xl">X</span>
          <SvgMenu
            v-else
            aria-hidden="true"
            focusable="false"
            class="pointer-events-none w-5 ml-2.4 -mr-3"
            role="img"
          />
        </button>
        <nav
          v-if="menuVisible"
          class="bg-glass fixed right-3 bottom-16 m-1 items-center text-base leading-tight border-1 rounded-lg border-green-600 border-opacity-50 shadow-lg sm:absolute sm:-right-2 sm:top-10 sm:bottom-auto sm:rounded-t-none"
        >
          <ul
            class="mr-auto flex flex-col divide-y divide-green-600 divide-opacity-20"
          >
            <li>
              <NuxtLink
                to="/"
                @click="toggleMenu"
                class="block p-2 text-blue-gray-800 hover:text-green-600 focus:text-green-600 active:text-blue-gray-700 active:bg-green-600 active:bg-opacity-50 active:animate-ping"
                >{{ I18nNO.homeLink }}</NuxtLink
              >
            </li>
            <li>
              <NuxtLink
                to="/program"
                @click="toggleMenu"
                class="block p-2 text-blue-gray-800 hover:text-green-600 focus:text-green-600 active:text-blue-gray-700 active:bg-green-600 active:bg-opacity-50 active:animate-ping"
                >{{ I18nNO.calendarLink }}</NuxtLink
              >
            </li>
            <li>
              <NuxtLink
                to="/legg-til-kalender"
                @click="toggleMenu"
                class="block p-2 text-blue-gray-800 hover:text-green-600 focus:text-green-600 active:text-blue-gray-700 active:bg-green-600 active:bg-opacity-50 active:animate-ping"
                >{{ I18nNO.importCalendarLink }}</NuxtLink
              >
            </li>
            <li>
              <NuxtLink
                to="/kart"
                @click="toggleMenu"
                class="block p-2 text-blue-gray-800 hover:text-green-600 focus:text-green-600 active:text-blue-gray-700 active:bg-green-600 active:bg-opacity-50 active:animate-ping"
                >{{ I18nNO.mapLink }}</NuxtLink
              >
            </li>
            <li class="border-b-1 border-green-600">
              <NuxtLink
                to="/om-oss"
                @click="toggleMenu"
                class="block p-2 text-blue-gray-800 hover:text-green-600 focus:text-green-600 active:text-blue-gray-700 active:bg-green-600 active:bg-opacity-50 active:animate-ping"
                >{{ I18nNO.aboutLink }}</NuxtLink
              >
            </li>
            <!-- <li class="border-b-1 border-green-600">
              <NuxtLink
                to="/aktiviteter"
                @click="toggleMenu"
                class="block p-2 text-blue-gray-800 hover:text-green-600 focus:text-green-600 active:text-blue-gray-700 active:bg-green-600 active:bg-opacity-50 active:animate-ping"
                >{{ I18nNO.activitiesLink }}</NuxtLink
              >
            </li> -->
            <!-- <li>
              <NuxtLink -->
            <!-- cspell: ignore arrangorer -->
            <!-- to="/arrangorer"
                @click="toggleMenu"
                class="block p-2 text-blue-gray-800 hover:text-green-600 focus:text-green-600 active:text-blue-gray-700 active:bg-green-600 active:bg-opacity-50 active:animate-ping"
                >{{ I18nNO.organizersLink }}</NuxtLink
              >
            </li> -->
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>

<script setup lang="ts">
import Location from "@/data/Location";
import PlainI18nNO from "@/data/I18nNO.js";
const I18nNO = reactive(PlainI18nNO);

const menuVisible = ref(false);
const toggleMenu = () => (menuVisible.value = !menuVisible.value);

onMounted(() => {
  document.addEventListener("click", (event) => {
    if (
      !(event.target as HTMLElement).closest("#menu") &&
      !(event.target as HTMLElement).closest("#menu-button")
    ) {
      menuVisible.value = false;
    }
  });
});
</script>

<style>
.bg-glass {
  background-image: linear-gradient(
      100deg,
      rgb(255 255 255 / 40%) 30%,
      rgb(255 255 255/0%) 31%
    ),
    linear-gradient(
      118deg,
      rgb(255 255 255 / 85%) 60%,
      rgb(255 255 255/80%) 61%
    );
  @apply bg-opacity-75 backdrop-filter backdrop-blur-sm;
}
</style>
